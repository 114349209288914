<template>
  <div>
    <b-button
      class="mb-1"
      variant="flat-primary"
      @click="$router.go(-1)"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">Voltar</span>
    </b-button>

    <b-overlay
      :show="load"
      variant="transparent"
    >
      <RemessaInfo
        v-if="!load"
        :remessa="remessa"
        :clientes="numeroC"
        :total="total"
      />

      <RemessaOperacoes
        v-if="loadOperacoes"
        :operacoes="operacoes"
      />
    </b-overlay>

  </div>

</template>

<script>
import {
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import RemessaInfo from './cards/RemessaInfo.vue'
import RemessaOperacoes from './cards/RemessaOperacoes.vue'
import axios from '../../../../axios-auth'

export default {
  components: {
    RemessaOperacoes,
    RemessaInfo,
    BButton,
    BOverlay,
  },
  data() {
    return {
      load: true,
      loadOperacoes: false,
      remessa: {},
      operacoes: [],
      nClientes: [],
      numeroC: 0,
      valorOperacoes: [],
      total: 0,
    }
  },
  async created() {
    await this.getDados()
  },
  methods: {
    async getDados() {
      this.load = true
      await axios.get(`api/v1/remessas/edit/${this.$route.params.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.remessa.usuario_nome = res.data.dados.usuario_cadastrou
          this.remessa.id = res.data.dados.remessa.id
          this.remessa.credor_nome = res.data.dados.credor[0].nome
          this.remessa.numero_remessa = res.data.dados.remessa.numero_remessa
          this.remessa.data_remessa = this.dataHora(res.data.dados.remessa.data_remessa)
          this.remessa.data_entrada = this.dataHora(res.data.dados.remessa.data_entrada)
          this.remessa.operacaoTotal = res.data.dados.operacoes.length
          const dadosOperacoes = res.data.dados.operacoes
          const Operacoes = []
          let valorTotal = 0
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const prop in dadosOperacoes) {
            valorTotal = parseFloat(valorTotal) + parseFloat(valorTotal)
            Operacoes.push({
              id: dadosOperacoes[prop].id,
              numero_operacao: dadosOperacoes[prop].numero_operacao,
              cliente_id: dadosOperacoes[prop].cliente_id,
              cliente_nome: dadosOperacoes[prop].cliente.nome,
              cliente_cpf_cnpj: dadosOperacoes[prop].cliente.cpf_cnpj,
              data_vencimento: this.dataHora(dadosOperacoes[prop].data_vencimento, true),
              valor_nominal: this.valorBr(dadosOperacoes[prop].valor_nominal),
            })
            this.nClientes.push(dadosOperacoes[prop].cliente_id)
            this.valorOperacoes.push(dadosOperacoes[prop].valor_nominal)
          }
          this.remessa.valorTotal = valorTotal
          this.operacoes = Operacoes
          this.loadOperacoes = true
          this.load = false
          this.numeroClientes()
          this.totalRemessa()
        })
    },
    totalRemessa() {
      const dados = this.valorOperacoes
      this.total = dados.reduce((total, i) => total + i)
    },

    numeroClientes() {
      const num = this.nClientes.filter((este, i) => this.nClientes.indexOf(este) === i)
      this.numeroC = num.length
    },
    dataHora(dados, op = false) {
      const a = dados
      const b = a.split('T')
      const c = b[0].split('-')
      const d = b[1].split('.')
      const e = d[0].split(':')
      const dataBr = `${c[2]}/${c[1]}/${c[0]}`
      const horaBr = `${e[0]}:${c[1]}`
      const dataHora = `${dataBr} ${horaBr}`
      if (op) {
        return dataBr
      }
      return dataHora
    },
    valorBr(dados, op = false) {
      const valorRS = dados.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
      const valor = dados.toLocaleString('pt-br', { minimumFractionDigits: 2 })
      if (op) {
        return valorRS
      }
      return valor
    },
  },
}
</script>

<style>

</style>
