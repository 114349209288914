<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                Remessa: {{ remessa.numero_remessa }}
              </h4>
              <!-- <span class="card-text">{{ remessa.numero }}</span> -->
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-if="permissions['remessa.excluir'].permissao"
                variant="outline-danger"
                @click="deletRemessa(remessa.id, remessa.numero_remessa)"
              >
                Deletar
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center ml-1 mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="UserIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ clientes }}
              </h5>
              <small>Clientes</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-warning"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ remessa.operacaoTotal }}
              </h5>
              <small>Operações</small>
            </div>
          </div>
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ valorBr(total) }}
              </h5>
              <small>Total</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="AtSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Usuário</span>
            </th>
            <td class="pb-50">
              {{ remessa.usuario_nome }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Credor</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ remessa.credor_nome }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="WatchIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Data Remessa</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ remessa.data_remessa }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="WatchIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Data Entrada</span>
            </th>
            <td class="pb-50">
              {{ remessa.data_entrada }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import axios from '../../../../../axios-auth'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    remessa: {
      type: Object,
      required: true,
    },
    clientes: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      permissions: null,
    }
  },
  created() {
    this.permissions = JSON.parse(localStorage.permissoes).remessa
  },
  methods: {
    deletRemessa(id, remessa) {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja deletar a remessa ${remessa}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero deletar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/remessas/delete/${id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          })
            .then(res => {
              if (res.data) {
                this.$router.push({ name: 'remessas' })
                this.$swal({
                  icon: 'success',
                  title: 'Deletado!',
                  text: 'Remessa deletada com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style>

</style>
